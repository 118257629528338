import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton, RefreshButton,
  trans,
  PaginationComponent, DownloadButton, ImportButton, FilterButton
} from "@comps/components";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Space, message, Badge, Skeleton, Row, Col, Card, Button, Popover, Spin, DatePicker } from 'antd';
import { FilterComponent } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq, getRelatedDataReq } from "./requests";
import { Header } from "antd/es/layout/layout";
import CreateTodo from "./components/CreateTodo";
import UpdateTodo from "./components/UpdateTodo";
import { checkPermissions, createEmploymentResource } from '@comps/commonFunctions';
import dayjs from 'dayjs';
import UpdateCaseStatus from "./components/UpdateCaseStatus";
import { downloadSampleReq } from "../../../../common/requests";
import ImportSample from "../../../../common/ImportSample";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import './todos.css';
import { getDatesFilters } from "../../../../common/commonFilterFields";
const { RangePicker } = DatePicker;
const langKey = Const["todo"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
var employments = [];
const IndexTodo = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords = 0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [fromdate, setFrom] = useState(dayjs().startOf('month'));
  const [todate, setTo] = useState(dayjs().endOf('month'));
  const [filters, setFilters] = useState(`from_|${fromdate.format('YYYY-MM-DD')};to_|${todate.format('YYYY-MM-DD')};`);
  const [counts, setCounts] = useState([]);
  const [statuses, setStatuses] = useState([0, 1, 2]);
  const [pendings, setPendings] = useState([]);
  const [achieved, setAchieved] = useState([]);
  const [approved, setApproved] = useState([]);

  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================
  const getStatus = (status) => {

    if (!status) {
      return { status: "Pending", color: 'orange' }
    }
    if (status === 1) {
      return { status: "Completed", color: 'blue' }
    }
    if (status === 2) {
      return { status: "Approved", color: 'green' }
    }
    if (status === 3) {
      return { status: "Rejected", color: 'red' }
    }
  }


  const statusBadge = (status, count) => {
    if (count === '') {
      return <Badge
        count={ getStatus(status).status }
        color={ getStatus(status).color }
        style={ { cursor: "pointer" } }
      />
    }
    return <Badge
      count={ getStatus(status).status + ': ' + count }
      color={ getStatus(status).color }
      style={ { cursor: "pointer" } }
    />
  }

  const updateStatus = (record) => {
    // if (record.status === 2) {
    //   message.info('Case has beens checked by QA')
    //   return
    // }
    setChildComponent(
      <UpdateCaseStatus onCompleted={ onCompleted } record={ record } disabled={ record.status === 2 || record.status === 3 } employments={ employments } />
    );
  }

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    if (employments.length === 0 && (checkPermissions('todo', 'create') || checkPermissions('todo', 'update'))) {
      // getSupportingData()
    }
    // eslint-disable-next-line
  }, [filters, currentPage]);




  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (data, response) => {

    setDataSource(data);
    setFrom(dayjs(response.message.from));
    setTo(dayjs(response.message.to));
    setPendings(pendingTodos(data));
    setAchieved(completedTodos(data));
    setApproved(approvedTodos(data));
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateTodo onCompleted={ onCompleted } record={ record } disabled={ record.status === 2 || record.status === 3 } employments={ employments } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateTodo onCompleted={ onCompleted } record={ record } disabled={ true } employments={ employments } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    getAllData()
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateTodo onCompleted={ onCompleted } onSuccess={ onSuccess } employments={ employments } />);
  };

  const onCompleted = (data) => {
    if (data) {
      getAllData()
    }
    setChildComponent(null);
  };


  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={ props.modkey } selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------
  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleReq, 'to-do/downloadSample', onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `todos.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }


  const pendingTodos = (data) => {
    //datasource where status is 0 or 3
    const filteredData = data && data.length === 0 ? [] : data.filter(item => item.status === 0 || item.status === 3)
    //setPendings(filteredData)
    return filteredData
  }
  const completedTodos = (data) => {
    //data where status is 0 or 3
    const filteredData = data && data.length === 0 ? [] : data.filter(item => item.status === 1)
    // setAchieved(filteredData)
    return filteredData
  }
  const approvedTodos = (data) => {
    //data where status is 0 or 3
    const filteredData = data && data.length === 0 ? [] : data.filter(item => item.status === 2)
    //setApproved(filteredData)
    return filteredData
  }

  //todo actions
  const actions = (todo) => {
    let type = 'assignee';
    if (todo.assignee_id === authUser.employment_id) {
      type = 'assignee';
    }
    if (todo.qa_ids_array.includes(authUser.employment_id)) {
      type = "assigner"
    }
    return [
      // <Popover content={ dropdown(todo) } title=""
      //   trigger="click"
      // >
      //   <EllipsisOutlined key="ellipsis" />
      // </Popover>,

      //update status text
      <span onClick={ () => updateStatus(todo) }>{ statusBadge(todo.status, '') }</span>,
      <div style={ {
        display: 'flex',

        justifyContent: 'center'
      } }>
        <div>
          { todo.assignee_name }
        </div>
        <div>
          <img style={ { borderRadius: '40px', width: 20, height: 20 } } src={ todo.profile_pic_url !== "images/arnold-avatar.jpg" ? todo.profile_pic_url : "/images/arnold-avatar.jpg" } alt="" className="me-2" width={ 20 } />
        </div>

      </div>,
    ];
  }

  const todoTitle = (todo) => {
    return (
      <>
        <div onClick={ () => onView(todo) }>{ todo.title ? todo.title : "No Title" }</div>
        {/* <small className="text-primary">{ dayjs(todo.assigned_at).format("DD/MM/YYYY") }</small> */ }

      </>
    )
  }

  const statusHeading = (status) => {

    let title = '';
    let count = 0;
    if (status === 0) {
      title = "Todo"
      count = pendings.length
    }
    if (status === 1) {
      title = "Completed"
      count = achieved.length
    }
    if (status === 2) {
      title = "Approved"
      count = approved.length
    }
    return (
      <>
        <div>{ title + ' ' + count }</div>
      </>
    )
  }

  const todoCard = (status) => {
    let todos = pendings;
    if (status === 0) {
      todos = pendings;
    }
    if (status === 1) {
      todos = achieved;
    }
    if (status === 2) {
      todos = approved;
    }

    const extra = (todo) => {
      return (
        <>
          <ActionComponent each={ todo } modkey={ props.modkey } key="extra" onEdit={ onEdit } onDelete={ onDelete } />
        </>
      )
    }

    return (
      <>
        { todos.map((todo) => (
          <Card hoverable={ true } title={ todoTitle(todo) } className="single-card" actions={ actions(todo) } extra={ extra(todo) }>
            <div onClick={ () => onView(todo) }>{ todo.description ? todo.description : 'No description' }</div>

          </Card>
        )) }
      </>
    )
  }

  //change date
  const onSelectDateRange = (value, dates) => {
    setFrom(value[0]);
    setTo(value[1]);
    let payload = `from_|${fromdate.format('YYYY-MM-DD')};to_|${todate.format('YYYY-MM-DD')};`;
    if (dates) {
      payload = `from_|${dates[0]};to_|${dates[1]};`;
      setFilters(payload);
    }
  }

  return (
    <>

      { childComponent }
      <BodyComponent>
        <Spin spinning={ loader } tip="loading...">
          <Header style={ headerStyle }>

            <Space>
              <div style={ { marginRight: '250px', marginLeft: '0px' } }>
                <RangePicker
                  defaultValue={ [dayjs(fromdate, 'DD-MM-YYYY'), dayjs(todate, 'DD-MM-YYYY')] }
                  showTime={ false }
                  format="DD-MM-YYYY"
                  onChange={ (value, dateString) => onSelectDateRange(value, dateString) }
                // onChange={ onSelectDateRange }
                />
              </div>
              <RefreshButton onClick={ getAllData } />
              { checkPermissions(props.modkey, 'create') && <>
                <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={ getAllData } api="to-do/import" module="Loans" onCompleted={ () => setChildComponent(null) } />) } />
                <DownloadButton onClick={ onDownloadSample } />
                <CreateButton onClick={ onCreate } />
              </> }
              <FilterButton onClick={ onOpenFilter } />
            </Space>

          </Header>


          <Row gutter={ 16 } className="card-container">
            { statuses.map(status => (
              <Col span={ 8 } className="card-col">
                <Card
                  className="single-card-container"
                  title={ statusHeading(status) }
                  //title={ statusBadge(0, dataSource.pending) }
                  bordered={ true }
                >
                  { dataSource.length > 0 && todoCard(status) }
                </Card>
              </Col>
            )) }
          </Row>
        </Spin>

      </BodyComponent>
      {/* <PaginationComponent pagination={ pagination } modkey={ props.modkey } setCurrentPage={ setCurrentPage } currentPage={ currentPage } /> */ }
    </>
  );
};

export default IndexTodo;

const filterArray = [];
const filterFields = filterArray.concat(getDatesFilters(''))


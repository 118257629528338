import { createSlice } from "@reduxjs/toolkit";



const filterOptionsSlice = createSlice({
  name: "filterOptions",
  initialState: {},
  reducers: {
    setFilterOptions: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearFilterOptions: (state) => {
      return {};
    },
  },
})

export const { setFilterOptions, clearFilterOptions } = filterOptionsSlice.actions;

export default filterOptionsSlice.reducer;
import { post, get } from "@utils/axios";

export const getBirthdaysReq = async () => {
  return await get("directives/birthday");
}

export const getGenderCountReq = async () => {
  return await get("directives/genderBreakdown");
}

export const getCheckinTrendReq = async (payload) => {
  return await get("directives/employeeDailyArrivals?start_date=" + payload.start_date + "&end_date=" + payload.end_date);
}

export const getAbsentsMonthWiseReq = async (payload) => {
  return await get("directives/absentsMonthWise?start_date=" + payload.start_date + "&end_date=" + payload.end_date);
}

export const getHeadCountByAgeReq = async () => {
  return await get("directives/employeesCountByAge");
}

export const getHeadCountByBranchReq = async () => {
  return await get("directives/employeesCountByBranch");
}

export const getHeadCountByDepartReq = async () => {
  return await get("directives/employeesCountByDepartment");
}
export const getHeadCountByDesignationReq = async () => {
  return await get("directives/employeesCountByDesignation");
}
export const getHeadCountByEmployeTypeReq = async () => {
  return await get("directives/employeesCountByEmployeeType");
}
export const getHeadCountByLocationReq = async () => {
  return await get("directives/employeesCountByLocation");
}
export const getHeadCountByServiceReq = async () => {
  return await get("directives/employeesCountByService");
}
export const getHeadCountByTerminationReq = async () => {
  return await get("directives/employeesTerminationCountByReason");
}

export const getAttendanceMonthReportReq = async (payload) => {
  return await get("directives/monthlyAttendanceCount?start_date=" + payload.start_date + "&end_date=" + payload.end_date);
}
export const getAttendanceSummaryReq = async (payload) => {
  return await get("directives/attendancesummary?start_date=" + payload.start_date + "&end_date=" + payload.end_date);
}

export const getDepartmentSummaryDetailReq = async (payload) => {
  return await post("directives/departmentattendancesummary", payload);
}

export const getDepartmentAttendanceDetailReq = async (payload) => {
  return await post("directives/departmentattendancedetails", payload);
}

export const getAttendanceByBranchDetailReq = async (payload) => {
  return await post("directives/shiftattendancesummary", payload);
}
export const getDashboardReq = async () => {
  return await get("directives/allDashboards");
}

export const getLateArrivalsReq = async (payload) => {
  return await get("directives/dailyLateArrivals?date=" + payload.date);
}
export const getEarlyArrivalsReq = async (payload) => {
  return await get("directives/dailyEarlyArrivals?date=" + payload.date);
}

export const getLeaveSummaryReq = async () => {
  return await get("directives/leavetype");
}

export const getActiveEmployeeReq = async () => {
  return await get("directives/activeEmployee");
}

export const getNoticPeriodEmployeeReq = async () => {
  return await get("directives/employeesNoticePeriod");
}

export const getProbationEmployeeReq = async () => {
  return await get("directives/probationConfirmation");
}

export const getEoeEmployeeReq = async () => {
  return await get("directives/eoeEmployees");
}

export const getMyTeamReq = async () => {
  return await get("directives/my-team");
}
export const getMarkAttendanceReq = async () => {
  return await post("directives/markAttendance");
}

export const getMarkAttendanceCheckinReq = async (payload) => {
  return await post("directives/markAttendance", payload);
}

export const getNewsLettersReq = async () => {
  return await get("directives/news");
}
export const testNoticationReq = async (payload) => {
  return await get("common/send-test-event");
}

export const getDailyAbsentsReq = async (payload) => {
  return await get("directives/dailyAbsents?date=" + payload.date);
}
export const getDailyMissingsReq = async (payload) => {
  return await get("directives/dailyMissings?date=" + payload.date);
}
export const getDailyDayoffsReq = async (payload) => {
  return await get("directives/dailyDayoffs?date=" + payload.date);
}

import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Switch, Select } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputDate, InputCheck, SelectMultiWithSearch, SelectWithSearch } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq, getBranchesReq, getDepartmentHierarchyReq } from "../requests";
import { Const } from "../../constants";
import { getResourceDataReq, createEmploymentResource, createSelectList } from "@comps/commonFunctions";
import dayjs from 'dayjs';
const langKey = Const["myAttendance"].lngKey
const formName = "createAttendanceRequest";
const CalculateAttendance = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isAll, setIsAll] = useState(false)
  const [type, setType] = useState('employment_ids')
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [branches, setBranches] = useState([]);
  const [form] = Form.useForm()
  useEffect(() => {
    getRemoteData()
    // eslint-disable-next-line
  }, []);

  const getRemoteData = () => {
    getEmployments()
    makeRequest(setLoader, getDepartmentHierarchyReq, null, onSuccessDepartments, null);
    makeRequest(setLoader, getBranchesReq, null, onGetBranches, onGetBranches);
  }

  const onGetBranches = (response, data) => {
    setBranches(createSelectList(data.organization_structure_data.branch))
  }
  const onSuccessDepartments = (response) => {

    const newList = [];

    response.departments && response.departments.forEach(each => (
      newList.push({
        label: each.name,
        value: each.hierarchy_code
      })
    ))
    setDepartments(newList)
  }
  const getEmployments = (id) => {
    let payload = {
      page: 1,
      type: 'employment',
      filters: `search_|${id}`
    }
    makeRequest(setLoader, getResourceDataReq, payload, ongetAllEmployees, null);
  };
  const ongetAllEmployees = (response) => {
    setEmployees(createEmploymentResource(response.resources.data))
    setLoader(false)
  }

  //save data
  const onSubmit = (data) => {

    const payload = {
      attendance: { ...data, type: type, all: isAll, start: data.start.format('YYYY-MM-DD'), end: data.end.format('YYYY-MM-DD') }
    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    //setSaveLoader(true)
  }
  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success(res.message);
    message.info('Calculating in the background please wait...');
    props.onCompleted(false);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ 'Calculate' } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>

        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>
          <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
            className="da-mb-16"

            initialValue={ dayjs().startOf('month') }
          >
            <InputDate />
          </Form.Item>
          <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
            className="da-mb-16"
            initialValue={ dayjs().endOf('month') }
          >
            <InputDate />
          </Form.Item>

          <Form.Item name="all" rules={ rules.all } label={ trans(`${langKey}.all`) }
            className="da-mb-16"

            valuePropName="checked"
          >
            <InputCheck defaultChecked={ isAll } onChange={ (e) => setIsAll(e.target.checked) } />
          </Form.Item>
          { !isAll && <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
            className="da-mb-16"
            initialValue={ 'employment_ids' }

          >

            <SelectWithSearch options={ [
              {
                label: 'Branches',
                value: "branch_ids",
              },
              {
                label: 'Departments',
                value: "department_ids"
              },
              {
                label: 'Employees',
                value: "employment_ids"
              },

            ] } onChange={ (value) => setType(value) } />

          </Form.Item> }
          {
            !isAll && type === 'employment_ids' &&
            <Form.Item name="employment_ids" rules={ rules.employment_ids } label={ "Employees" }
              className="da-mb-16"

              valuePropName="checked"
            >
              <SelectMultiWithSearch
                loading={ false }

                //type="search"
                // prefix={<SearchOutlined />}
                placeholder="Employees"
                size="large"
                options={ employees }

                onSearch={ getEmployments }
              />
            </Form.Item>
          }
          {
            !isAll && type === "department_ids" &&
            <Form.Item name="department_ids" rules={ rules.department_ids } label={ "Departments" }
              className="da-mb-16"
              valuePropName="checked"
            >
              <SelectMultiWithSearch
                loading={ false }
                placeholder="Departments"
                size="large"
                options={ departments }
              />
            </Form.Item>
          }
          {
            !isAll && type === "branch_ids" &&
            <Form.Item name="branch_ids" rules={ rules.branch_ids } label={ "Branches" }
              className="da-mb-16"
              valuePropName="checked"
            >
              <SelectMultiWithSearch
                loading={ false }
                placeholder="Branches"
                size="large"
                options={ branches }
              />
            </Form.Item>
          }
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CalculateAttendance

const rules = {
  start: [
    { required: true, message: trans(`${langKey}.start`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end`) },
  ],

  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  branch_ids: [
    { required: true, message: "Field Required" },
  ],
  department_ids: [
    { required: true, message: "Field Required" },
  ],

};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}


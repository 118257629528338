import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq, updateDataReq } from "../requests";
import { Commons, Const, Validations } from "../../../constants";
import dayjs from 'dayjs';
import { useSelector } from "react-redux";

const langKey = Const["todo"].lngKey
const formName = "createForm";

const UpdateCaseStatus = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [form] = Form.useForm();
  const [statuses, setStatuses] = useState(false);
  const authUser = useSelector((state) => state.auth.authUser);

  useEffect(() => {
    setFormRecord();
    // eslint-disable-next-line
  }, []);

  const setFormRecord = () => {
    let record = props.record;
    props.record.assigned_at = dayjs(props.record.assigned_at)
    props.record.qa_ids = props.record.qa_ids_array


    form.setFieldsValue(props.record);


    let st = [{ label: "Pending", value: 0 }, { label: "Complete", value: 1 }];
    if (record.assignee_id === authUser.employment_id) {

    }
    if (record.qa_ids_array.includes(authUser.employment_id) || props.disabled) {
      st.push({ label: "Approved", value: 2 })
    }


    setStatuses(st)
  }

  const onSubmit = (data) => {
    setSaveLoader(true)
    data.assigned_at = dayjs(data.assigned_at).format('YYYY-MM-DD')
    let obj = props.record;
    if (data.qa_remarks) {
      obj.qa_remarks = data.qa_remarks;
    }
    if (data.assignee_remarks_remarks) {
      obj.assignee_remarks_remarks = data.assignee_remarks_remarks;
    }
    if (data.status === 2 || data.status === 3) {
      obj.checked_by = authUser.employment_id;
    }
    const payload = { todo: { ...props.record, ...data }, id: props.record.id };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }


  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ 'Update Status' } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>

        <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
          className="da-mb-16"

        >
          <InputText placeholder={ trans(`${langKey}.title`) } disabled />
        </Form.Item>


        <Form.Item name="assigned_at" rules={ rules.assigned_at } label={ trans(`${langKey}.assigned_at`) }
          className="da-mb-16"

        >
          <InputDate disabled />
        </Form.Item>
        <Form.Item name="status" rules={ rules.status } label={ trans(`${langKey}.status`) }
          className="da-mb-16"

        >
          <SelectWithSearch options={ statuses } />
        </Form.Item>

        { props.record.assignee_id === authUser.employment_id &&
          <Form.Item name="assignee_remarks" rules={ rules.assignee_remarks } label={ trans(`${langKey}.assignee_remarks`) }
            className="da-mb-16"

          >
            <InputTextArea placeholder={ trans(`${langKey}.assignee_remarks`) } />
          </Form.Item>
        }
        { props.record.qa_ids_array.includes(authUser.employment_id) &&
          <>
            <Form.Item name="weightage" rules={ rules.weightage } label={ trans(`${langKey}.weightage`) }
              className="da-mb-16"
            >
              <InputText type="number" placeholder={ trans(`${langKey}.weightage`) } />
            </Form.Item>
            <Form.Item name="qa_remarks" rules={ rules.qa_remarks } label={ trans(`${langKey}.qa_remarks`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.qa_remarks`) } />
            </Form.Item>
          </>
        }

        {
          props.record.checked_by && <Form.Item name="checked_by_name" rules={ rules.checked_by_name } label={ trans(`${langKey}.checked_by_name`) }
            className="da-mb-16"

          >
            <InputText placeholder={ trans(`${langKey}.checked_by_name`) } disabled />
          </Form.Item>
        }
      </Form>
    </ModalComponent>
  )
}

export default UpdateCaseStatus

const rules = {
  name: [
    { required: true, message: trans(Validations.name) },
  ],
  qa_ids: [
    { required: true, message: trans(`${langKey}.qa_ids`) },
  ],
  assignee_id: [
    { required: true, message: trans(`${langKey}.assignee_id`) },
  ],
  assigned_at: [
    { required: true, message: trans(`${langKey}.assigned_at`) },

  ],
  description: [
    { required: false, message: trans(Commons.description) },
  ],
  title: [
    { required: true, message: trans(`${langKey}.title`) },

  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },

  ],
  protocol: [
    { required: true, message: trans(`${langKey}.protocol`) },

  ],
  status: [
    { required: true, message: trans(`${langKey}.status`) },

  ],
  weightage: [
    { required: false, message: trans(`${langKey}.weightage`) },

  ],
  //... other fields


};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
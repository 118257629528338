import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexPayrollConfigurations from "./settings/configurations/IndexPayrollConfigurations";
import IndexTaxStructure from "./settings/taxStructures/IndexTaxStructure";
import IndexSaleIncentives from "./incentive/IndexSaleIncentive";
import IndexSaleSalaryRevisions from "./salaryRevisions/IndexSalaryRevisions";
import IndexTaxAdjustments from "./openingBalances/IndexTaxAdjustments";
import IndexPayrolls from "./payrolls/IndexPayrolls";
import CreatePayroll from "./payrolls/CreatePayroll";
import UpdatePayroll from "./payrolls/UpdatePayroll";
import IndexFormula from "./settings/formula/IndexForumula";
import IndexRule from "./settings/rule/IndexRule";
import IndexPayitem from "./settings/payitem/IndexPayitem";
import IndexLeaveEncashment from "./leaveEncashments/IndexLeaveEncashment";
import IndexMyPayslip from "./myPayslips/IndexMyPayslip";
import IndexVouchers from "./vouchers/IndexVouchers";
import IndexCompanyPayslip from "./companyPayslips/IndexCompanyPayslip";
import IndexSalarySheets from "./salarySheet/IndexSalarySheets";
import IndexFinalSettlement from "./finalSettlements/IndexFinalSettlement";
import IndexDailyWagePayrolls from "./dailyWagesPayroll/IndexDailyWagePayrolls";
import IndexCustomPayitem from "./settings/customPayitems/IndexCustomPayitem";
import IndexGratuity from "./settings/gratuity/IndexGratuity";
import IndexIncentives from "./incentive/components/IndexIncentives";

const PayrollManagementModules = () => {
  return (
    <Routes>
      <Route path="settings/configurations" element={ <IndexPayrollConfigurations modkey="payrollConfigurations" /> } />
      <Route path="settings/tax-structures" element={ <IndexTaxStructure modkey="taxStructures"/> } />
      <Route path="sale-incentive" element={ <IndexIncentives modkey="saleIncentive"/> } />
      <Route path="leave-encashments" element={ <IndexLeaveEncashment modkey="leaveEncashments"/> } />
      <Route path="settings/formula" element={ <IndexFormula modkey="formula"/> } />
      <Route path="settings/rule" element={ <IndexRule modkey="payrollRule"/> } />
      <Route path="settings/payitem" element={ <IndexPayitem modkey="payitem"/> } />
      <Route path="settings/gratuity" element={ <IndexGratuity modkey="gratuity"/> } />
      <Route path="salary-revisions" element={ <IndexSaleSalaryRevisions modkey="salaryRevisions"/> } />
      <Route path="opening-balances" element={ <IndexTaxAdjustments modkey="taxAdjustment"/> } />
      <Route path="payrolls" element={ <IndexPayrolls modkey="payrolls"/> } />
      <Route path="payrolls/create" element={ <CreatePayroll /> } />
      <Route path="payrolls/edit/:id" element={ <UpdatePayroll /> } />
      <Route path="payrolls/view/:id" element={ <UpdatePayroll /> } />
      <Route path="my-payslips" element={ <IndexMyPayslip modkey="myPayslips"/> } />
      <Route path="company-payslips" element={ <IndexCompanyPayslip modkey="companyPayslips"/> } />
      <Route path="vouchers" element={ <IndexVouchers modkey="vouchers"/> } />
      <Route path="salary-sheet" element={ <IndexSalarySheets modkey="salarySheet"/> } />
      <Route path="final-settlements" element={ <IndexFinalSettlement modkey="finalSettlements"/> } />
      <Route path="daily-wage-payroll/index" element={ <IndexDailyWagePayrolls modkey="dailyWagePayroll"/> } />
      <Route path="custom-payitem/item/:name/:id" element={ <IndexCustomPayitem modkey="customPayitem"/> } />
    </Routes>
  );
}

export default PayrollManagementModules;

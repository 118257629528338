import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getDailyMissingsReq } from '../requests';
import "../dashboard.css"
import { InputDate } from '@comps/components';
import dayjs from 'dayjs';
import { Commons, Const, colors } from "../constants";
import { convertTimeTotz } from '../../../wrappers/commonFunctions';

const moment = require('moment');

const DailyMissings = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment());

  useEffect(() => {
    let payload = {
      "date": startDate ? startDate.format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
    }
    makeRequest(setLoader, getDailyMissingsReq, payload, onSuccess, onError)
  }
    , [startDate]);

  const onSuccess = (data) => {
    setData(data.daily_missings);
  }

  const onError = (error) => {
  }

  const getStatusClass = (status) => {
    if (status === 'On Time') {
      return 'bg-success'
    }
    if (status === 'Day Off') {
      return 'bg-off'
    }
    if (status === 'Late' || status === 'Early') {
      return 'bg-warning'
    }
    if (status === 'Travel') {
      return 'bg-info'
    }
    if (status === 'Leave') {
      return 'bg-leave'
    }
    if (status === 'Absent') {
      return 'bg-absent'
    }
  }

  return (
    <div className='widgetbox' >
      <Row>
        <Col span={ 12 }>
          <h3>Missings</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
            <Row gutter={ 10 }>
              <Col span={ 24 }>
                <InputDate onChange={ setStartDate } defaultValue={ dayjs(startDate) } placeholder="Date" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className='widgetcontent widgetboxfixH'>
        <Spin spinning={ loader } tip="Loading...">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Code</th>
                <th scope="col">Missing</th>
                <th scope="col">Expected</th>
              </tr>
            </thead>
            <tbody>
              { data && data.map((row, index) =>
                <tr>
                  <th scope="row">{ index + 1 }</th>
                  <td>{ row.full_name }</td>
                  <td>{ row.emp_code }</td>
                  <td>{ row.checkin_status === 'Missing' ? "Checkin" : 'Checkout' }</td>
                  <td>{ row.checkin_status === 'Missing' ? convertTimeTotz(row.checkin_expected_time).format('hh:mm a') : convertTimeTotz(row.checkout_expected_time).format('hh:mm a') }</td>
                </tr>
              ) }

            </tbody>
          </table>
        </Spin>
      </div>
    </div>
  );
}

export default DailyMissings;
import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, SelectResourceList, SelectResourceListSecond } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const, Validations } from "../../../constants";
import dayjs from 'dayjs';
const langKey = Const["todo"].lngKey
const formName = "createForm";

const CreateTodo = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({});
    // eslint-disable-next-line
  }, []);


  const onSubmit = (data) => {
    setSaveLoader(true)
    data.assigned_at = dayjs(data.assigned_at).format('YYYY-MM-DD')
    const payload = { todo: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } initialValues={ {} } scrollToFirstError>

        <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
          className="da-mb-16"

        >
          <InputText placeholder={ trans(`${langKey}.title`) } />
        </Form.Item>
        <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
          className="da-mb-16"

        >
          <InputText placeholder={ "Type" } />
        </Form.Item>
        <Form.Item name="protocol" rules={ rules.protocol } label={ trans(`${langKey}.protocol`) }
          className="da-mb-16"

        >
          <InputText placeholder={ trans(`${langKey}.protocol`) } />
        </Form.Item>
        <Form.Item name="assignee_id" rules={ rules.assignee_id } label={ trans(`${langKey}.assignee_id`) }
          className="da-mb-16"

        >
          <SelectResourceList placeholder={ trans(`${langKey}.assignee_id`) } type="employment" value={ null } />
        </Form.Item>
        <Form.Item name="qa_ids" rules={ rules.qa_ids } label={ trans(`${langKey}.qa_ids`) }
          className="da-mb-16"

        >
          <SelectResourceListSecond mode="multiple" placeholder={ trans(`${langKey}.qa_ids`) } type="employment" value={ null } />
        </Form.Item>

        <Form.Item name="assigned_at" rules={ rules.assigned_at } label={ trans(`${langKey}.assigned_at`) }
          className="da-mb-16"
          initialValue={ dayjs() }
        >
          <InputDate disabledDate={ (current) => {
            // Disable dates before today
            return current && current < dayjs().startOf('day');
          } } />
        </Form.Item>

        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"

        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>

      </Form>
    </ModalComponent>
  )
}

export default CreateTodo

const rules = {
  name: [
    { required: true, message: trans(Validations.name) },
  ],
  qa_ids: [
    { required: true, message: trans(`${langKey}.qa_ids`) },
  ],
  assignee_id: [
    { required: true, message: trans(`${langKey}.assignee_id`) },
  ],
  assigned_at: [
    { required: true, message: trans(`${langKey}.assigned_at`) },

  ],
  description: [
    { required: false, message: trans(Commons.description) },
  ],
  title: [
    { required: true, message: trans(`${langKey}.title`) },

  ],
  type: [
    { required: false, message: trans(`${langKey}.type`) },

  ],
  protocol: [
    { required: true, message: trans(`${langKey}.protocol`) },

  ],
  //... other fields


};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton, RefreshButton,
  trans,
  PaginationComponent, DownloadButton, ImportButton
} from "@comps/components";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Space, message, Badge, Skeleton } from 'antd';
import { FilterComponent } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq, getRelatedDataReq } from "./requests";
import { Header } from "antd/es/layout/layout";
import CreateTodo from "./components/CreateTodo";
import UpdateTodo from "./components/UpdateTodo";
import { checkPermissions, createEmploymentResource } from '@comps/commonFunctions';
import dayjs from 'dayjs';
import UpdateCaseStatus from "./components/UpdateCaseStatus";
import { downloadSampleReq } from "../../../../common/requests";
import ImportSample from "../../../../common/ImportSample";
const langKey = Const["todo"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
var employments = [];
const IndexDentalCase = (props) => {

  const totalRecords = 0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState();
  const [counts, setCounts] = useState([]);
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================
  const getStatus = (status) => {

    if (!status) {
      return { status: "Pending", color: 'orange' }
    }
    if (status === 1) {
      return { status: "Completed", color: 'green' }
    }
    if (status === 2) {
      return { status: "Approved", color: 'green' }
    }
    if (status === 3) {
      return { status: "Rejected", color: 'red' }
    }
  }


  const statusBadge = (status, count) => {
    return <Badge
      count={ getStatus(status).status + ': ' + count }
      color={ getStatus(status).color }
      style={ { cursor: "pointer" } }
    />
  }

  const updateStatus = (record) => {
    // if (record.status === 2) {
    //   message.info('Case has beens checked by QA')
    //   return
    // }
    setChildComponent(
      <UpdateCaseStatus onCompleted={ onCompleted } record={ record } disabled={ record.status === 2 || record.status === 3 } employments={ employments } />
    );
  }
  const columns = [

    {
      key: 'title',
      title: trans(`${langKey}.title`),
      dataIndex: "title",
    },
    {
      key: 'type',
      title: trans(`${langKey}.type`),
      dataIndex: "type",
    },
    {
      key: 'assignee_name',
      title: trans(`${langKey}.assignee_id`),
      dataIndex: "assignee_name",
    },

    // {
    //   key: 'protocol',
    //   title: trans(`${langKey}.protocol`),
    //   dataIndex: "protocol",
    // },

    // {
    //   key: 'qa_names',
    //   title: trans(`${langKey}.qa_ids`),
    //   render: (record) => {
    //     return record.qa_names ? record.qa_names.join(', ') : ''
    //   }
    // },

    {
      key: 'assigned_at',
      title: trans(`${langKey}.assigned_at`),
      render: (record) => {
        return record.assigned_at ? dayjs(record.assigned_at).format('DD-MM-YYYY') : ''
      }
    },
    {
      key: 'status',
      title: "Status",
      render: (record) => {
        return <Badge
          onClick={ () => updateStatus(record) }
          count={ getStatus(record.status).status }
          color={ getStatus(record.status).color }
          style={ { cursor: "pointer" } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={ props.modkey }
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        >

        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    if (employments.length === 0 && (checkPermissions('todo', 'create') || checkPermissions('todo', 'update'))) {
      getSupportingData()
    }
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getSupportingData = (data, res) => {
    setLoader(true);
    setLoading(true)
    makeRequest(setLoader, getRelatedDataReq, null, onGetSupportingData, onGetSupportingData);
  }
  const onGetSupportingData = (data, res) => {
    setLoader(false);
    setLoading(false)
    employments = createEmploymentResource(res.supporting_data.employments);
  }

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {

    setPagination(response);
    setDataSource(response.data);

  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateTodo onCompleted={ onCompleted } record={ record } disabled={ record.status === 2 || record.status === 3 } employments={ employments } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateTodo onCompleted={ onCompleted } record={ record } disabled={ true } employments={ employments } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    getAllData()
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateTodo onCompleted={ onCompleted } onSuccess={ onSuccess } employments={ employments } />);
  };

  const onCompleted = (data) => {
    if (data) {
      getAllData()
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={ props.modkey } selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------
  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleReq, 'to-do/downloadSample', onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `todos.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  return (
    <>

      { childComponent }
      <BodyComponent>
        <Skeleton loading={ loading }>
          <Header style={ headerStyle }>

            <Space>
              <RefreshButton onClick={ getAllData } />
              { checkPermissions('todo', 'create') && <>
                <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={ getAllData } api="to-do/import" module="Loans" onCompleted={ () => setChildComponent(null) } />) } />
                <DownloadButton onClick={ onDownloadSample } />
                <CreateButton onClick={ onCreate } />
              </> }
              {/* <FilterButton onClick={ onOpenFilter } /> */ }
            </Space>

          </Header>
          <table>
            <thead>
              <tr>
                <th>{ statusBadge(0, pagination.pending) }</th>
                <th>{ statusBadge(1, pagination.achieved) }</th>
                <th>{ statusBadge(2, pagination.approved) }</th>
                <th>{ statusBadge(3, pagination.rejected) }</th>
              </tr>
            </thead>
          </table>
          <TableComponent modkey={ props.modkey }
            loader={ loader }
            columns={ columns }
            dataSource={ dataSource }
            pagination={ { ...pagination, total: totalRecords } }
            onChange={ handleTableChange }
          />
        </Skeleton>

      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={ props.modkey } setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexDentalCase;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },

]


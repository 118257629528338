import { useState, useEffect } from "react";
import {
  ModalComponent,
  trans,
  SaveButton,
  CancelButton,
  InputDate
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Const } from "../constants";
import { Form, message, Skeleton } from 'antd';
import { InputText } from "@comps/components";
import { runPayrollDataReq } from "./requests";
import { convertTimeTotz } from '@comps/commonFunctions';
import dayjs from 'dayjs'
import { InputCheck } from '../../../wrappers/components';
const langKey = Const["payroll"].lngKey;
const formName = "runPayroll";

const RunPayroll = (props) => {

  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)

  const [currentPayrollMonth, setCurrentPayrollMonth] = useState(null)
  const [calculateAttendance, setCalculateAttendance] = useState(false);

  useEffect(() => {
    getRelatedData();
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    form.setFieldValue('name', props.record.name)
    let month = null;

    if (props.record.history && props.record.history.status === 'Closed' && props.record.history.month) {
      month = dayjs(props.record.history.month).add(1, 'month');
    }
    else {
      month = props.record.history && props.record.history.month ? dayjs(props.record.history.month) : null;
    }
    form.setFieldValue('month', month)
    onChangeMonth(month)
    setCurrentPayrollMonth(month)
  }

  const onSubmit = (data) => {
    const start = data.start.format('YYYY-MM-DD')
    const end = data.end.format('YYYY-MM-DD')
    const month = data.month.set('date', 1).format('YYYY-MM-DD')
    const payload = {
      payroll: {
        name: data.name,
        id: props.record.id,
        calculate_attendance: calculateAttendance,
        month: month,
        start: start,
        end: end,
        cut_offs: [
          {
            name: 'Attendance',
            start: start,
            end: end,
            is_start: props.record.history ? false : true,
            is_end: true,
          }
        ]
      }
    }
    makeRequest(setLoader, runPayrollDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    //navigate('/payrollmanagement/payrolls')
    message.success("Saved successfully");
    props.onCompleted(data.payrolls.data);
  }

  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }


  const onChangeMonth = (date) => {
    let payrol = props.record;
    const processingDate = props.record.processing_date
    if (date) {
      // Get the first date of the current date's month
      let firstDateOfMonth = date.startOf('month');
      // Get the last date of the current date's month
      let lastDateOfMonth = date.endOf('month');
      if (payrol.is_fixed_days) {
        //lastDateOfMonth = payrol.no_of_fixed_days
      }
      if (processingDate) {
        if (processingDate > 15) {
          firstDateOfMonth = firstDateOfMonth.subtract(1, 'months')
          lastDateOfMonth = lastDateOfMonth.add(1, 'month').date(processingDate)
        }
        firstDateOfMonth = firstDateOfMonth.add(processingDate, 'day')

      }

      if (payrol.is_fixed_days && !payrol.process_at_month_end) {
        lastDateOfMonth = firstDateOfMonth.add(payrol.no_of_fixed_days, 'days');
      }

      if (payrol.history && payrol.history && payrol.history.cut_offs.length > 0 && !props.record.history.closing_date) {
        firstDateOfMonth = dayjs(payrol.history.cut_offs[0].start)
        lastDateOfMonth = dayjs(payrol.history.cut_offs[0].end)
      }



      form.setFieldsValue({
        start: firstDateOfMonth,
        end: lastDateOfMonth
      })
    }
    else {
      form.setFieldsValue({
        start: null,
        end: null
      })
    }
  }
  const disabledDate = (date) => {
    const year = currentPayrollMonth ? currentPayrollMonth.year() : convertTimeTotz().year()
    if (!currentPayrollMonth) {
      return false
    }
    let monthIndex = currentPayrollMonth.month();
    if (props.record.history && props.record.history.status === 'Closed') {
      monthIndex = monthIndex + 1;
    }
    if (date && date.year() !== year) {
      return true
    }
    return date && date.month() !== monthIndex;
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ loader } form={ formName } key="create_button" htmlType="submit" text="Run" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(`${langKey}.mainTitle`) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>

          <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
            className="da-mb-16"

          >
            <InputText placeholder={ trans(`${langKey}.name`) } disabled={ true } />
          </Form.Item>
          <Form.Item name="calculate_attendance" rules={ rules.calculate_attendance } label={ trans(`${langKey}.calculate_attendance`) }
            className="da-mb-16"

          >
            <InputCheck onChange={ (e) => setCalculateAttendance(e.target.checked) } />
          </Form.Item>
          <Form.Item name="month" rules={ rules.month } label={ trans(`${langKey}.month`) }
            className="da-mb-16"

          >
            <InputDate picker="month" onChange={ onChangeMonth } format='YYYY-MMMM' disabledDate={ disabledDate } />
          </Form.Item>

          {/* <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
            className="da-mb-16"

          >
            <InputDate picker="date" />
          </Form.Item>
          <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
            className="da-mb-16"

          >
            <InputDate picker="date" />
          </Form.Item> */}


          {/* <Divider>
            { trans(`${langKey}.cut_offs`) }
          </Divider> */}
          <div className="formlist-container">
            <h5 className="formlist-title">{ trans(`${langKey}.cut_offs`) }</h5>
            <table className="table">
              <thead>
                <tr style={ { borderTop: "1px solid #C9D6DF" } }>
                  <th style={ { paddingLeft: 20 } }> { trans(`${langKey}.name`) }</th>
                  <th> { trans(`${langKey}.start`) }</th>
                  <th> { trans(`${langKey}.end`) }</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={ { paddingLeft: 20 } }>
                    { 'Attendance' }
                  </td>
                  <td>
                    <Form.Item name="start" rules={ rules.start }
                      className="da-mb-16"

                    >
                      <InputDate picker="date" disabled={ false } />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item name="end" rules={ rules.end }
                      className="da-mb-16"

                    >
                      <InputDate picker="date" disabled={ false } />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default RunPayroll

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  month: [
    { required: true, message: trans(`${langKey}.month`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end`) },
  ],


};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}

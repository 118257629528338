
import { Layout, Menu, Spin, Badge, Avatar, message, Select, Tooltip } from 'antd';
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeRequestStateless } from '@utils/helpers';
import { getNavigationReq } from '@mods/common/requests';
import SubMenu from 'antd/es/menu/SubMenu';
import { trans, SelectWithSearch } from "@comps/components";
import { encryptLocalData, decryptLocalData } from '@comps/commonFunctions';
import './navigation.css';
import { countNavNotificationsReq, getRolePermissionsReq } from './requests';
import { pusherInitializer } from '@comps/commonFunctions';
import { PlusOutlined } from '@ant-design/icons';
import { MinimizeOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilterOptions } from '../../../redux/filterOptionsSlice';

const langKey = 'navigation';
const { Sider } = Layout;
var count = 0;
const NavSide = (props) => {

  const searchModuleRef = useRef(null);

  const [permissionOptions, setPermissionOptions] = useState([]);
  const [defaultSearchMenu, setDefaultSearchMenu] = useState(null)
  const authUser = useSelector((state) => state.auth.authUser);
  const navigate = useNavigate()
  const location = useLocation();
  const [loading, setLoading] = useState(true)
  const [collapsed, setCollapsed] = useState(false);


  const [navigation, setNavigation] = useState({})
  const [menuItems, setMenuItems] = useState([])
  const [theme, setTheme] = useState('light');
  const [current, setCurrent] = useState('');
  const [currentModuleKey, setModuleKey] = useState(getLastSubstring(location.pathname))
  const [navKey, setNavKey] = useState('')
  const [openKeys, setOpenKeys] = useState(
    JSON.parse(localStorage.getItem('openSubMenuKeys')) || ['']
  );
  const [notificationsBykey, setNotificationByKey] = useState({});
  const [notificationModules, setNotificationsModules] = useState([])
  // Inside the NavSide component
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [customPayitems, setCustomPayitem] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    //on press ctrl+k to focus on search module
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'm') {
        event.preventDefault(); // Prevent the default browser search
        searchModuleRef.current.focus(); // Focus on the input field
      }

    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown); // Clean up the event listener
    };

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);


  useEffect(() => {
    // Set the collapsed state based on the window width
    if (windowWidth <= 829) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [windowWidth]);


  useEffect(() => {
    getNavWithPermissions()

    // eslint-disable-next-line
  }, [current, openKeys]);

  useEffect(() => {
    getNavNotificationsCount('notifications')
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pusher = pusherInitializer();
    const channel = pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('refresh-navigation-event', function (data) {
      getNavNotificationsCount('notifications')
    });
    // Cleanup function for unsubscribing and unbinding when the component is unmounted
    return () => {
      // Unbind the event handler
      channel.unbind('refresh-navigation-event');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-line
  });

  useEffect(() => {
    const pusher = pusherInitializer();
    const channel = pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('RolePermissionsUpdatedEvent', function (data) {
      if (data && Number(data.role_id) === authUser.role_id) {
        localStorage.setItem('navigation', '');
        //localStorage.setItem('permissions', '');
        message.info('Role Permissions Updated!')
        getRolePermissions(authUser.role_id);
        getNavWithPermissions();
      }
    });
    // Cleanup function for unsubscribing and unbinding when the component is unmounted
    return () => {
      // Unbind the event handler
      channel.unbind('RolePermissionsUpdatedEvent');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-linehavehave
  });
  //=================pusher for refresh =====================

  //===============end pusher=========================

  const getRolePermissions = (roleId) => {
    setLoading(true)
    makeRequestStateless(getRolePermissionsReq, roleId, onGetPermissions, null);
  }
  const onGetPermissions = (data) => {
    setLoading(false)
    encryptLocalData('permissions', data);
    setLoading(false)
  }
  //get notifications
  const getNavNotificationsCount = (navKey) => {
    makeRequestStateless(countNavNotificationsReq, navKey, onSuccessNavNotifications, null);
  }
  const onSuccessNavNotifications = (res) => {
    if (res.sub_modules) {
      setNotificationsModules(res.sub_modules)
    }
    else {
      setNotificationsModules([])
    }
    setNotificationByKey(res)
  }

  const createSelectPermissions = (data) => {
    const newList = [];
    data.forEach((item, index) => {
      if (item.parentId && !item.module_key.includes('management')) {
        newList.push({
          value: getSearchValue(item),
          label: translateSearchNav(item),
        })
      }
    })

    setPermissionOptions(newList);
  }

  const getSearchValue = (item) => {
    let value = getUrl(item.sref);
    if (item.sref.includes('roster')) {
      value = value + '_shift';
    }
    if (item.sref.includes('lend')) {
      value = value + '_loan';
    }

    return value;
  }
  const translateSearchNav = (item) => {
    let res = translateNav(item);

    return res;
  }

  const onChangeNavSearch = (url) => {
    if (url && url.includes('_shift')) {
      url = url.replace('_shift', '');
    }
    if (url && url.includes('_loan')) {
      url = url.replace('_loan', '');
    }
    navigate(url)
    setCurrent(url)
    setDefaultSearchMenu(null)
  }

  const getReportsPermissions = () => {
    const res = [
      {
        "sref": "app.reports.all_reports",
        "module_key": "reports",
        "name": "REPORTS",
        "icon": "fa fa-bar-chart",
        "tag": null,
        "id": 169,
        "hasPermisson": 1,
        "parentId": null,
        "sort_order": 233,
        "super_admin": 0,
        "hide_in_nav": 0,
        "is_mobile": 0,
        "role_id": 4,
        "identifier": "_"
      },
      {
        "sref": "app.reports.all_reports",
        "module_key": "reportsAll",
        "name": "ALL_REPORTS",
        "icon": "fa fa-pie-chart",
        "tag": "REPORTS,ALL_REPORTS",
        "id": 170,
        "hasPermisson": 1,
        "parentId": 169,
        "sort_order": 234,
        "super_admin": 0,
        "hide_in_nav": 0,
        "is_mobile": 0,
        "role_id": 4,
        "identifier": "_"
      },
    ]

    return res[0];
  }
  //get navigation function
  const getNavWithPermissions = () => {

    localStorage.setItem('selectedMenuItem', current);
    localStorage.setItem('openSubMenuKeys', JSON.stringify(openKeys));
    const nav = decryptLocalData('navigation');
    if (nav) {
      if (authUser.super_admin) {
        nav.permissions.push(getReportsPermissions())
      }
      setNavigation(nav)
      setMenuItems(nav.permissions)
      setLoading(false)
      createSelectPermissions(nav.permissions)
    }
    else {
      setLoading(true)
      makeRequestStateless(getNavigationReq, null, onGetNavigation, null);
    }

  }

  const onGetNavigation = (data, res) => {
    setLoading(false)
    encryptLocalData('navigation', res.data);
    createSelectPermissions(res.data.permissions);
    if (authUser.super_admin) {
      res.data.permissions.push(getReportsPermissions())
    }
    setNavigation(res.data)
    setLoading(false)
  }

  const formatPermissions = (data) => {
    const result = [];
    const map = new Map();
    if (data !== undefined && data) {
      data.forEach(item => {
        map.set(item.id, { ...item, children: [] });
      });

      map.forEach(item => {
        if (item.parentId === null) {
          result.push(item);
        }
        else {
          let parent = map.get(item.parentId);

          if (parent) {
            parent.children.push(item);
          }
          if (item.module_key === "customPayitem") {
            if (customPayitems.length > 0) {
              item.children = customPayitems;
            }
            else {
              if (navigation.custom_pay_items && navigation.custom_pay_items.length > 0) {
                const customPis = navigation.custom_pay_items;
                customPis && customPis.length && customPis.forEach(each => {
                  each.sref = 'app.payrollmanagement.custom_payitem.item/' + each.name + '/' + each.id
                  each.module_key = each.name + '_custom_payitem'
                  each.hasPermisson = true
                  each.icon = (each.type === 'deduction' ? '-' : '+')
                  each.parentId = item.id
                  each.tags = 'payitem' + each.name
                  item.children.push(each);
                });
                setCustomPayitem(item.children);
              }
            }

          }
        }
      });
    }
    return renderMenuItems(result)
  }
  ///========================Start Notifications========================================
  const countSubmoduleNotify = (module, name) => {
    module.length > 0 && module.forEach((module, modIndex) => {
      if (module.name === undefined) {
        count = 0
      }
      if (name === module.name) {
        count = module.value
      }
      else {
        if (module.sub_modules.length > 0) {

          count = countSubmoduleNotify(module.sub_modules, name)
        }
      }
    })
    if (module.length < 1) {
      count = 0;
    }
    return count
  }

  const countNotify = (name) => {
    const modules = notificationModules;
    modules.length > 0 && modules.forEach((each) => {
      if (each.name !== name) {
        count = 0
      }
    })

    modules.length > 0 && modules.forEach((module, modIndex) => {
      if (module.name === undefined) {
        count = 0;
      }

      if (name === module.name) {
        count = module.value
      }
      else {
        if (module.sub_modules.length > 0) {
          count = countSubmoduleNotify(module.sub_modules, name)
        }
      }

    })

    if (count === 0) {
      return ''
    }
    return <Badge size="small" count={ count } color='#f5222d' />
  }

  const translateNav = (item) => {

    if (item.code !== undefined) {
      return item.name
    }

    else {
      return trans(`${langKey}.${item.module_key}`)
    }
  }
  const getNavIcon = (item, type) => {

    if (type === "subMenu") {
      return <img src={ `/sidebaricon/${item.module_key}.svg` } alt='icon' />
    }
    if (type === "menu") {
      if (item.code !== undefined) {
        return item.icon === "+" ? <PlusOutlined /> : <MinimizeOutlined />
      }
      return <img src={ `/sidebaricon/${item.module_key}.svg` } alt='icon' style={ { borderRadius: '10px', color: 'blue' } } />
    }
  }

  const getModuleKey = (item) => {
    if (item.module_key === 'dashboard') {
      return '';
    }
    return item.module_key
  }
  //========================End Notifications========================================
  function renderMenuItems(data) {
    return data.map(item => {
      const name = item.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
      item.name = name.join(' ');
      const url = getUrl(item.sref);

      if (item.children !== undefined && item.children.length > 0) {
        return (
          <SubMenu key={ url + '|' + getModuleKey(item) } title={ <label style={ { color: currentModuleKey === getModuleKey(item) ? "#4079FC" : '' } }>
            { translateNav(item) }
            <small>{ countNotify(getModuleKey(item)) }</small>
          </label> } subTitle="sub" icon={ getNavIcon(item, 'subMenu') } >
            { renderMenuItems(item.children) }
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item key={ url + '|' + getModuleKey(item) } title={ translateNav(item) } modulekey={ getModuleKey(item) } icon={ getNavIcon(item, "menu") }>
            <label style={ { color: currentModuleKey === getModuleKey(item) ? "#4079FC" : '' } }><a href={ `/${getUrl(item.sref)}#` } onClick={ () => onClickMenu(getModuleKey(item)) } style={ { textDecoration: 'none' } }>{ translateNav(item) }</a> <small >{ countNotify(getModuleKey(item)) }</small></label>
          </Menu.Item>
        );
      }
    });
  }


  const getUrl = (sref) => {
    let url = sref ? sref.replace(/^app\./, '').replace(/_/g, '-').replace(/\./g, '/') : '';
    //remove extra
    if (url === "masters/general/accolades") {
      url = url.replace(/\/accolades/g, '');
    }
    if (url === "masters/system/users") {
      url = url.replace(/\/users/g, '');
    }
    if (url === "payrollmanagement/payrolls/index") {
      url = url.replace(/\/index/g, '');
    }
    //
    if (url === 'dashboard') {
      url = ''
    }
    return url;
  }

  // const getReportTypes = () => {
  //   const items = navigation.report_types;
  // }

  const handleOpenChange = (keys) => {

    const count = keys.length;
    let opened = [keys[count - 1]];
    if (keys.length > 0 && keys[count - 1].includes('/')) {
      opened = keys;
    }

    setOpenKeys(opened);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const setCurrentModuleKey = (key) => {
    // Use a regular expression to match everything after "|"
    let match = key.match(/\|(.*)/);
    // Get the captured group from the match
    let modulekey = match ? match[1] : '';

    setModuleKey(modulekey)
    localStorage.setItem('modulekey', modulekey)
    dispatch(clearFilterOptions())
  }

  function getLastSubstring(path) {
    // Split the string by slashes
    const parts = path.split('/');
    // Get the last part
    return parts.pop();
  }

  const onClickMenu = (key) => {
    if (key === 'dashboard|') {
      key = ''
    }
    setCurrentModuleKey(key)
    navigate('/' + key.replace(/\|(.*)/, ""))
    setCurrent(key.replace(/\|(.*)/, ""))
  }
  //========end side menu nav data =====
  return (
    <>
      <Sider width={ 260 } cursor={ "" } trigger={ null } collapsible collapsed={ collapsed } theme="light" >
        <div
          onClick={ handleCollapse }
          style={ {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: "#fff",
            borderRight: '100px solid #C9D6DF',
          } }
        >
          <Tooltip title={ collapsed ? '+ Maximize Menu' : "- Minimize Menu" }>
            <div style={ { position: "absolute", right: -55, top: 20, zIndex: 1 } }>
              { collapsed ?
                <img src='../../sidebaricon/Group.svg' alt="logo"
                  className={ collapsed ? 'color-filtered' : '' }
                /> :
                <img src='../../sidebaricon/Group37201.svg' alt="logo " /> }
            </div>
          </Tooltip>
        </div>


        <Spin spinning={ loading }>
          <div>
            <div>
              <Menu mode="inline" theme={ theme } selectedKeys={ [current] }
                className='menu-logo'
                defaultSelectedKeys={ [navKey] }
                onClick={ ({ key }) => {
                  navigate('/')
                } }
                items={ [
                  {
                    label: <span><img src={ require('@assets/images/KLOK.png') } alt='icon' /></span>,
                    icon: <img src={ require('@assets/images/image170.png') } alt='icon' />,
                  },
                ] }
              />
            </div>
            <div className='ms-4 me-4'>
              <Select
                ref={ searchModuleRef }
                style={ {
                  width: '100%',
                } }
                placeholder={ 'Quik Search...   Ctrl+M' }
                allowClear
                showSearch={ true }
                options={ permissionOptions }
                onChange={ onChangeNavSearch }
                value={ defaultSearchMenu }
              />
            </div>

          </div>

          <div className='menucontainer'>

            <Menu mode="inline" theme={ theme } selectedKeys={ [current] }
              openKeys={ openKeys }
              onOpenChange={ handleOpenChange }
              onClick={ ({ key }) => {
                onClickMenu(key)
              } }
            >
              { formatPermissions(navigation.permissions !== undefined && navigation.permissions) }
            </Menu>
          </div>
        </Spin>

      </Sider>
    </>
  )
}

export default NavSide;
import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateTargetIncentiveConfigReq, getOrgFieldsReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import dayjs from 'dayjs';


const langKey = Const["targetIncentives"].lngKey
const formName = "createAttendanceRequest";
const UpdateTargetIncentiveConfiguration = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [allowOvertime, setAllowOvertime] = useState(true);
  const [orgFieldName, setOrgFieldName] = useState('grade');
  const [form] = Form.useForm()
  const [orgFields, setOrgFields] = useState([]);
  const [type, setType] = useState('monthly');
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {

    setType(props.record.type);
    setOrgFieldName(props.record.organization_field_name);
    setAllowOvertime(props.record.is_allow_overtime);
    form.setFieldsValue(props.record);
    setLoading(true)
    makeRequest(setLoader, getOrgFieldsReq, null, onGetOrgFields, onGetOrgFields);
  }
  const onGetOrgFields = (data, res) => {
    setLoading(false)
    const fields = {
      grade: createSelectList(res.organization_structure_data.grade),
      department: createSelectList(res.organization_structure_data.department),
      designation: createSelectList(res.organization_structure_data.designation),
      job_titles: createSelectList(res.organization_structure_data.job_title),

    }
    setOrgFields(fields);


  }

  const onSubmit = (data) => {
    const payload = {
      id: props.record.id,
      target_incentive_config: { ...props.record, ...data }
    }
    makeRequest(setLoader, updateTargetIncentiveConfigReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success(res.message);
    props.onCompleted(data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="organization_field_name" rules={ rules.organization_field_name } label={ trans(`${langKey}.organization_field_name`) }
                className="da-mb-16"
                initialValue={ orgFieldName }
              >
                <SelectWithSearch
                  onChange={ setOrgFieldName }
                  options={ [
                    {
                      label: 'Grade',
                      value: 'grade'
                    },
                    {
                      label: 'Designation',
                      value: "designation"
                    },
                    {
                      label: 'Job Title',
                      value: "job_title"
                    },
                    {
                      label: 'Department',
                      value: "department"
                    },

                  ] }
                  placeholder={ trans(`${langKey}.organization_field_name`) }
                />
              </Form.Item>
            </Col>


            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="organization_field_value" rules={ rules.organization_field_value } label={ trans(`${langKey}.organization_field_value`) }
                className="da-mb-16"

              >
                <SelectWithSearch
                  options={ orgFields[orgFieldName] }
                  placeholder={ trans(`${langKey}.organization_field_value`) }
                />

              </Form.Item>
            </Col>


            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
                className="da-mb-16"
                initialValue={ type }
              >
                <SelectWithSearch
                  options={ [
                    {
                      label: "Daily",
                      value: 'daily'
                    },
                    {
                      label: "Monthly",
                      value: 'monthly'
                    }
                  ] }
                  placeholder={ trans(`${langKey}.type`) }
                  onChange={ (e) => setType(e) }
                />
              </Form.Item>
            </Col>


            {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ type === 'monthly' ? trans(`${langKey}.month`) : trans(`${langKey}.date`) }
                className="da-mb-16"

              >
                <InputDate picker={ type === 'daily' ? 'date' : "month" } />
              </Form.Item>
            </Col> */}


            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="no_of_cases" rules={ rules.no_of_cases } label={ trans(`${langKey}.no_of_cases`) }
                className="da-mb-16"

                initialValue={ 0 }
              >
                <InputText placeholder={ trans(`${langKey}.no_of_cases`) } type="number" onWheel={ (e) => e.target.blur() } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="case_price" rules={ rules.case_price } label={ trans(`${langKey}.case_price`) }
                className="da-mb-16"

                initialValue={ 0 }
              >
                <InputText placeholder={ trans(`${langKey}.case_price`) } type="number" onWheel={ (e) => e.target.blur() } />
              </Form.Item>
            </Col>


            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_allow_overtime" rules={ rules.is_allow_overtime } label={ trans(`${langKey}.is_allow_overtime`) }
                className="da-mb-16"
                initialValue={ allowOvertime }
              >
                <SelectWithSearch onChange={ setAllowOvertime } placeholder={ trans(`${langKey}.is_allow_overtime`) } options={
                  [
                    {
                      label: "Yes",
                      value: true
                    },
                    {
                      label: "No",
                      value: false
                    }
                  ] } />

              </Form.Item>
            </Col>

            {
              allowOvertime !== 0 && allowOvertime && <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="overtime_case_price" rules={ rules.overtime_case_price } label={ trans(`${langKey}.overtime_case_price`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['overtime_case_price']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.overtime_case_price`) } type="number" onWheel={ (e) => e.target.blur() } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="min_cases_for_overtime" rules={ rules.min_cases_for_overtime } label={ trans(`${langKey}.min_cases_for_overtime`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['min_cases_for_overtime']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.min_cases_for_overtime`) } type="number" onWheel={ (e) => e.target.blur() } />
                  </Form.Item>
                </Col>
              </>
            }


          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateTargetIncentiveConfiguration

const rules = {
  organization_field_name: [
    { required: true, message: trans(`${langKey}.organization_field_name`) },
  ],
  organization_field_value: [
    { required: true, message: trans(`${langKey}.organization_field_value`) },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  case_price: [
    { required: true, message: trans(`${langKey}.case_price`) },
  ],
  overtime_case_price: [
    { required: true, message: trans(`${langKey}.overtime_case_price`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  min_cases_for_overtime: [
    { required: true, message: trans(`${langKey}.min_cases_for_overtime`) },
  ],
  is_allow_overtime: [
    { required: true, message: trans(`${langKey}.is_allow_overtime`) },
  ],
  no_of_cases: [
    { required: true, message: trans(`${langKey}.no_of_cases`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}


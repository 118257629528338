import { get, del, post, put } from "@utils/axios";

const api = "saleIncentives";
export const getAllDataReq = (payload) => {
  return get(`${api}`)
};
export const getDataByIdReq = (payload) => {
  return get(`${api}/${payload.id}`,payload)
};

export const createDataReq = (payload) => {
  return post(`${api}`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};
export const cancelRequestReq = (payload) => {
  return post('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${api}/${payload.id}?page=1&filters=${payload.filters}`);
};
export const applyIncentiveReq = (payload) => {
  return post(`${api}/compute`,payload);
};

export const getRosterReq = (payload) => {
  return post(`${api}/getRoster`, payload);
};
export const getExpectedTimeReq = (payload) => {
  return get(`${api}/getExpectedTime?&attendance_date=${payload.date}&employment_id=${payload.employment_id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const onDateChange = (payload) => {
  return post(`${api}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${api}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataReq = (payload) => {
  return post(`${api}/getRelatedData`)
};

//target incentive apis
export const getTargetIncetiveConfigReq = (payload) => {
  return get(`target-incentive-configurations`, payload)
};

export const getOrgFieldsReq = () => {
  return get(`filters?company_id=1&fields=page=1&filters=organization_structure_fields_|designation,grade,department,job_title;`);
};
export const createTargetIncentiveConfigReq = (payload) => {
  return post(`target-incentive-configurations/create`, payload)
};
export const updateTargetIncentiveConfigReq = (payload) => {
  return put(`target-incentive-configurations/update/${payload.id}`, payload)
};
export const deleteTargetIncentiveConfigReq = (payload) => {
  return del(`target-incentive-configurations/delete/${payload}`)
};





const key = "payrollManagement";
const baseRoute = "/payrollManagement";

export const Const = {
  payrollConfiguration: {
    lngKey: `${key}.payrollConfiguration`,
    route: ``,
  },
  taxStructure: {
    lngKey: `${key}.taxStructure`,
    route: ``,
  },
  saleIncentives: {
    lngKey: `${key}.saleIncentives`,
    route: ``,
  },
  targetIncentives: {
    lngKey: `${key}.targetIncentives`,
    route: ``,
  },
  salaryRevisions: {
    lngKey: `${key}.salaryRevisions`,
    route: ``,
  },
  taxAdjustments: {
    lngKey: `${key}.taxAdjustments`,
    route: ``,
  },
  payroll: {
    lngKey: `${key}.payroll`,
    route: ``,
  },
  formula: {
    lngKey: `${key}.formula`,
    route: ``,
  },
  rule: {
    lngKey: `${key}.rule`,
    route: ``,
  },
  payitem: {
    lngKey: `${key}.payitem`,
    route: ``,
  },
  expression: {
    lngKey: `${key}.expression`,
    route: ``,
  },
  leaveEncashment: {
    lngKey: `${key}.leaveEncashment`,
    route: ``,
  },
  myPayslip: {
    lngKey: `${key}.myPayslip`,
    route: ``,
  },
  vouchers: {
    lngKey: `${key}.vouchers`,
    route: ``,
  },
  salarySheets: {
    lngKey: `${key}.salarySheets`,
    route: ``,
  },
  finalSettlement: {
    lngKey: `${key}.finalSettlement`,
    route: ``,
  },
  dailyWagePayroll: {
    lngKey: `${key}.dailyWagePayroll`,
    route: ``,
  },
  gratuity: {
    lngKey: `${key}.gratuity`,
    route: ``,
  },
};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
  history: `${cmn}.history`,
  flow: `${cmn}.flow`,
  employment: `${cmn}.employment`,
  employee: `${cmn}.employee`,
};
export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f "
}
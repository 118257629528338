
import { Divider, Layout, Space, Tabs } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  HeaderComponent,
} from "@comps/components";
import IndexTargetIncentive from '../IndexTargetIncentive';
import IndexSaleIncentives from '../IndexSaleIncentive';


const { Content } = Layout;

const IndexIncentives = (props) => {
  const navigate = useNavigate()

  //------------use states start----------------
  const [tabPosition, setTabPosition] = useState('left');
  const [currentTab, setCurrentTab] = useState('Users');
  //-----------end use states--------------------
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  const onChangeTab = (e) => {
   // localStorage.setItem('filterPath', '/masters/system/' + e)
    setCurrentTab(e)
  };
  //-----------styling------------

  const TabContentStyle = { background: 'white', padding: '20px' }
  const pageConfig = {
    headers: {
      title: "",
      breadcrumb: [
        
      ],
    },
  };
  //----------end styling-----------
  return (
    <>
      <Space
        style={ {
          marginBottom: 24,
        } }
      >
      </Space>
      <Layout>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>

        </HeaderComponent>
        <Layout hasSider>
          <Content style={ TabContentStyle }>
            <Tabs
              centered
              tabPosition='top'
              onChange={ onChangeTab }
              tabBarStyle={ { fontWeight: 'bold' } }
            >
              <Tabs.TabPane tab="Target Incentive" key={ 'target_incentives' }>
                <IndexTargetIncentive modkey="saleIncentive"/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Sale Incentive" key={ 'sale_incentive' }>
                <IndexSaleIncentives modkey="saleIncentive"/>
              </Tabs.TabPane>
             

            </Tabs>
          </Content>

        </Layout>
      </Layout>
    </>
  );

};
export default IndexIncentives;
const orgKey = "generalMaster";
const baseRoute = "/masters/general";

export const Const = {
  accolade: {
    lngKey: `${orgKey}.accolade`,
    route: `${baseRoute}/accolade`,
  },
  skills: {
    lngKey: `${orgKey}.skills`,
    route: `${baseRoute}/skills`,
  },
  assetTypes: {
    lngKey: `${orgKey}.assettypes`,
    route: ``,
  },
  careerLevel: {
    lngKey: `${orgKey}.careerLevel`,
    route: ``,
  },
  bloodGroup: {
    lngKey: `${orgKey}.bloodGroup`,
    route: ``,
  },
  contractType: {
    lngKey: `${orgKey}.contractType`,
    route: ``,
  },
  employeeType: {
    lngKey: `${orgKey}.employeeType`,
    route: ``,
  },
  certification: {
    lngKey: `${orgKey}.certification`,
    route: ``,
  },
  maritalStatus: {
    lngKey: `${orgKey}.maritalStatus`,
    route: ``,
  },
  qualification: {
    lngKey: `${orgKey}.qualification`,
    route: ``,
  },
  relationship: {
    lngKey: `${orgKey}.relationship`,
    route: ``,
  },
  religion: {
    lngKey: `${orgKey}.religion`,
    route: ``,
  },
  salutation: {
    lngKey: `${orgKey}.salutation`,
    route: ``,
  },
  institute: {
    lngKey: `${orgKey}.institute`,
    route: ``,
  },
  language: {
    lngKey: `${orgKey}.language`,
    route: ``,
  },
  occupationType: {
    lngKey: `${orgKey}.occupationType`,
    route: ``,
  },
  country: {
    lngKey: `${orgKey}.country`,
    route: ``,
  },
  state: {
    lngKey: `${orgKey}.state`,
    route: ``,
  },
  city: {
    lngKey: `${orgKey}.city`,
    route: ``,
  },
  document: {
    lngKey: `${orgKey}.document`,
    route: ``,
  },
  asset: {
    lngKey: `${orgKey}.asset`,
    route: ``,
  },
  wows: {
    lngKey: `${orgKey}.wows`,
    route: ``,
  },
  documentType: {
    lngKey: `${orgKey}.documentType`,
    route: ``,
  },
  wow: {
    lngKey: `${orgKey}.wow`,
    route: ``,
  },
  newsLetter: {
    lngKey: `${orgKey}.newsLetter`,
    route: ``,
  },
  currency: {
    lngKey: `${orgKey}.currency`,
    route: ``,
  },
  benefit: {
    lngKey: `${orgKey}.benefit`,
    route: ``,
  },
  todo: {
    lngKey: `${orgKey}.todo`,
    route: ``,
  },

};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  count: `${cmn}.currentHeadCount`,
  identifier: `${cmn}.identifier`,
  code: `${cmn}.code`,
  companyName: `${cmn}.companyName`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
};

const val = "validations";
export const Validations = {
  name: `${val}.name.required`,
}

export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f",
  "bg-off": "#36c2aa"
}

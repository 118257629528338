import { get, del, post, postFd, put, putFd } from "@utils/axios";

const api = "todos";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=1&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};
export const updateStatusReq = (payload) => {
  return post(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const getRelatedDataReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments;`)
}